import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import Global from "../image/sticker.jpeg";
import TableContainer from "@mui/material/TableContainer";
import { useReactToPrint } from "react-to-print";
import Button from "@mui/material/Button";
import HelpIcon from "@mui/icons-material/Help";
import moment from "moment";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import QRCode from "react-qr-code";
import "./certificateList.css";
import { countOfStickerAnalytics } from "../../utils/analytics/certificateAnalytics";
import { multipleQRCertificateGuide } from "./guide";
import leftPanel from "../image/leftPanel.jpeg";
import topPanel from "../image/topPanel.jpeg";
import bottomPanel from "../image/bottomPanel.jpeg";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

import UnitechHeaderNabl from "../image/sticker_nabl.jpeg";
import UnitechHeaderNonNabl from "../image/sticker_nonnabl.jpeg";
import UnitechHeaderIas from "../image/sticker_ias.jpeg";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const labelContainerStyle = {
  border: "1px solid black",
  width: "100%",
  height: "100%",
  backgroundColor: "white",
  textAlign: "center",
  fontFamily: "Arial, sans-serif",
};

const calibratedTitleStyle = {
  backgroundColor: "green",
  color: "white",
  fontSize: "18px",
  fontWeight: "bold",
};

const labelFieldsStyle = {
  marginTop: "10px",
  padding: "0px 5px",
};

const calibratedTitleStyleMedium = {
  backgroundColor: "green",
  color: "white",
  fontSize: "17px",
  fontWeight: "bold",
};

export default function MultiQr() {
  const multipleQRCertificateGuides = multipleQRCertificateGuide();
  const printComponentRef = React.useRef();
  const [settingList, setSettingList] = React.useState({});
  const [allData, setAllData] = React.useState([]);
  const [size, setSize] = React.useState("large");
  const stikerIdFromStore = sessionStorage.getItem("multipleqrIDs");
  const params = stikerIdFromStore;
  let certificateIds = params;
  const certificateIdsArray = certificateIds?.split(",")?.map(Number);

  function fetchSettings() {
    axiosWithToken
      .get(BASE_URL + `settings`)
      .then((res) => {
         let settings = res.data;
        let _settingList = {};
        settings.forEach((setting) => {
          _settingList[setting.keyName] = setting.value;
        });
        setSettingList(_settingList);
      })
      .catch((err) => {
        console.error("Error fetching setting:", err);
      });
  }

  var refresh = () => {
    window.location.reload(false);
  };

  const getExpenseList = (event) => {
    let payload = {
      query: `SELECT certificates.DUCID AS certificate_DUCID, certificates.calibrationDate AS certificate_calibrationDate, certificates.nextDueDate AS certificate_nextDueDate, inst.instrumentName AS inst_instrumentName, certificates.id AS certificate_id, certificateAnalytics.stickerDownloadCounter AS certificateAnalytics_stickerDownloadCounter, certificateAnalytics.certificateDownloadCounter AS certificateAnalytics_certificateDownloadCounter, certificates.locationOfInstrument AS certificate_locationOfInstrument, certificates.certificateNumber AS certificate_certificateNumber, users.userName AS datasheet_calibratedby, datasheet.revisionNumber AS datasheet_revisionNumber,datasheet.requestedname AS datasheet_requestedname,srfInstruments.serialNumber AS srfInstruments_serialNumber,srfInstruments.calibrationType AS srf_calibrationType FROM certificates LEFT JOIN instruments AS inst ON certificates.instrumentId = inst.id LEFT JOIN datasheets AS datasheet ON certificates.id = datasheet.id LEFT JOIN certificateAnalytics ON certificates.id = certificateAnalytics.certificateId LEFT JOIN users ON datasheet.calibratedby = users.id LEFT JOIN srfInstruments ON certificates.id = srfInstruments.id  WHERE certificates.id IN (${params})`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, payload)
      .then((res) => {
        var splitIdArray = [];
        params
          ? params.split(",").map((ids) => {
              for (let i = 0; i < res.data.length; i++) {
                if (parseInt(ids) == res.data[i].certificate_id) {
                  splitIdArray.push(res.data[i]);
                }
              }
            })
          : console.log("no data");
        setAllData(splitIdArray);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    onAfterPrint: () => {
      refresh(); // Reload the page after printing is completed
    },
  });

  const renderSizeQrModal = () => {
    return allData.map((data, index) => {
      return (
        <div
          className="item"
          style={{
            height: "100%",
            width: "100%",
            pageBreakInside: "avoid",
            marginTop: "10px",
          }}
        >
          {size === "large" && (
            <div style={labelContainerStyle}>
              <div style={{ height: "100%" }}>
                <img
                  src={topPanel}
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    objectFit: "contain",
                  }}
                />
                <div style={{ flex: "1", display: "flex", height: "72%" }}>
                  <div style={{ width: "15%"}}>
                    <img
                      src={
                        data.srf_calibrationType == 1
                          ? UnitechHeaderNabl
                          : data.srf_calibrationType == 2
                          ? UnitechHeaderIas
                          : UnitechHeaderNonNabl
                      }
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <table
                      style={{
                        width: "100%",
                        height: "100%",
                        tableLayout: "fixed",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td className="stickerTdMsmall">
                            <b>Name of Equipment</b>
                          </td>
                          <td className="dataDivFromStickerMsmall">
                            {data?.datasheet_requestedname
                              ? data?.datasheet_requestedname
                              : data?.inst_instrumentName}
                          </td>
                        </tr>
                        <tr>
                          <td className="stickerTdMsmall">
                            <b>Serial No. Identification Number</b>
                          </td>
                          <td className="dataDivFromStickerMsmall">
                            {data.srfInstruments_serialNumber
                              ? `${data.srfInstruments_serialNumber} /`
                              : ""}{" "}
                            {data.certificate_DUCID}
                          </td>
                        </tr>
                        <tr>
                          <td className="stickerTdMsmall">
                            <b>Certificate Number</b>
                          </td>
                          <td className="dataDivFromStickerMsmall">
                            {`${data.certificate_certificateNumber}${
                              settingList["amendment"] === "true" &&
                              data.datasheet_revisionNumber > 0
                                ? `/AMD-${data.datasheet_revisionNumber}`
                                : ""
                            }`}{" "}
                          </td>
                        </tr>
                        <tr>
                          <td className="stickerTdMsmall">
                            <b>Calibration Done</b>
                          </td>
                          <td className="dataDivFromStickerMsmall">
                            {data.certificate_calibrationDate
                              ? moment(data.certificate_calibrationDate).format(
                                  "DD-MM-YYYY"
                                )
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td className="stickerTdMsmall">
                            <b>Calibration Due</b>
                          </td>
                          <td className="dataDivFromStickerMsmall">
                            {data.certificate_nextDueDate
                              ? moment(data.certificate_nextDueDate).format(
                                  "DD-MM-YYYY"
                                )
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <img
                  src={bottomPanel}
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    height: "10%",
                    objectFit: "cover",
                    marginTop: "-5px",
                  }}
                />
              </div>
            </div>
          )}
          {size === "medium" && (
            <div style={labelContainerStyle}>
              <div style={calibratedTitleStyleMedium}>CALIBRATED</div>
              <div style={labelFieldsStyle}>
                <div className="stikerFlexMedium">
                  <b>Instrument Name:</b>
                  <b>:</b>
                  <span>
                    {data?.datasheet_requestedname
                      ? data?.datasheet_requestedname
                      : data?.inst_instrumentName}
                  </span>
                </div>
                <div className="stikerFlexMedium">
                  <b>Id No</b>
                  <b>:</b>
                  <span>{data.certificate_DUCID}</span>
                </div>
                <div className="stikerFlexMedium">
                  <b>By</b>
                  <b>:</b>
                  <span>
                    {data.datasheet_calibratedby
                      ? data.datasheet_calibratedby
                      : ""}
                  </span>
                </div>
                <div className="stikerFlexMedium">
                  <b>Date</b>
                  <b>:</b>
                  <span>
                    {" "}
                    {data.certificate_calibrationDate
                      ? moment(data.certificate_calibrationDate).format(
                          "DD-MM-YYYY"
                        )
                      : ""}
                  </span>
                </div>
                <div className="stikerFlexMedium">
                  <b>Due</b>
                  <b>:</b>
                  <span>
                    {data.certificate_nextDueDate
                      ? moment(data.certificate_nextDueDate).format(
                          "DD-MM-YYYY"
                        )
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          )}
          {size === "small" && (
            <div style={labelContainerStyle}>
              <div style={calibratedTitleStyle}>CALIBRATED</div>
              <div style={labelFieldsStyle}>
                <div className="stikerFlexsmall">
                  <b>By</b>
                  <b>:</b>
                  <span>
                    {data.datasheet_calibratedby
                      ? data.datasheet_calibratedby
                      : ""}
                  </span>
                </div>
                <div className="stikerFlexsmall">
                  <b>Date</b>
                  <b>:</b>
                  <span>
                    {" "}
                    {data.certificate_calibrationDate
                      ? moment(data.certificate_calibrationDate).format(
                          "DD-MM-YYYY"
                        )
                      : ""}
                  </span>
                </div>
                <div className="stikerFlexsmall">
                  <b>Due</b>
                  <b>:</b>
                  <span>
                    {data.certificate_nextDueDate
                      ? moment(data.certificate_nextDueDate).format(
                          "DD-MM-YYYY"
                        )
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  useEffect(() => {
    getExpenseList();
    fetchSettings();
  }, []);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              multipleQRCertificateGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      <TableContainer
        component={Paper}
        sx={{ mt: 2, p: 2 }}
        className="hiddenscroll"
      >
        <Typography variant="h5" component="h5" style={{ float: "center" }}>
          <b>
            <u>Multiple QR Code</u>
          </b>
        </Typography>
        <hr />
        <Grid justifyContent={"flex-end"} container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box sx={{ width: "90%", m: "1.2rem auto" }}>
              <FormControl
                variant="standard"
                sx={{ minWidth: 230, float: "right", mb: "1rem" }}
              >
                <InputLabel>Choose Sizes</InputLabel>
                <Select
                  labelId="size-select-label"
                  value={size}
                  onChange={(e) => setSize(e.target.value)}
                >
                  <MenuItem value="large">Large</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="small">Small</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <div
          id="printStiker"
          className="row border-dark qrSticker"
          ref={printComponentRef}
          style={{ width: "95%", pageBreakAfter: "auto", margin: "auto" }}
        >
          <div
            style={{
              display: "grid",
              justifyContent: "center",
              columnGap: "30px",
              rowGap: "20px",
              gridTemplateColumns:
                size === "large"
                  ? `repeat(2, 360px)`
                  : size === "medium"
                  ? `repeat(2, 280px)`
                  : `repeat(3, 220px)`,
              gridAutoRows:
                size === "large"
                  ? "195px"
                  : size === "medium"
                  ? "145px"
                  : "120px",
            }}
          >
            {renderSizeQrModal()}
          </div>
        </div>
        <Button
          id="certificate-multipleqr_download"
          variant="contained"
          size="small"
          className="non"
          sx={{ m: 0, ml: 2, mt: 4 }}
          onClick={() => {
            handlePrint();
            countOfStickerAnalytics(certificateIdsArray);
            // refresh()
          }}
        >
          Print
        </Button>
      </TableContainer>
    </>
  );
}