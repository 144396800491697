// functionalities Check

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import { toPng } from "html-to-image";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Grid,
  Modal,
  Table,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
} from "@mui/material";
import html2canvas from "html2canvas";
import QRCode from "react-qr-code";
import { BASE_URL, DOMAIN } from "../../global";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import { Autocomplete } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HelpIcon from "@mui/icons-material/Help";
import UnitechHeader from "../image/certificate_header.png";
import UnitechHeaderNonNabl from "../image/certificate_header_nonnabl.png";
import UnitechHeaderIas from "../image/certificate_header_ias.png";
import UnitechFooter from "../image/certificate_footer.png";
import DraftImage from "../image/draft.png";
import LogoImage from "../image/WatermarkLogo.png";
import WithoutLogoImage from "../image/LogoWithoutletterhead.png";

import "./certificate.css";
import "./certificateList.css";
import RenderStaticTables from "./renderTables";
import { useGetRoleInfo } from "../login/Login";
import { makeChunks } from "../helper/helpers";
import { notifiy } from "../notification/Notification";
import { async } from "q";
import { countOfCertificatesAnalytics } from "../../utils/analytics/certificateAnalytics";
import { updateSRFAnalytics } from "../../utils/analytics/srfAnalytics";
import { viewCertificateGuide } from "./guide";
import { fetchAddresses } from "../invoice/productDetails";
import { allSettings } from "../../utils/components/allSettings";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";
import { ChangeCertificateStatus } from "./changeCertificateStatus";

const TableCell = withStyles({
  root: {
    border: "none",
    padding: "2px",
    margin: "0px",
    lineHeight: "1.32",
    fontSize: "12px",
    paddingLeft: "5px",
  },
})(MuiTableCell);

const useStyles = makeStyles({
  table: {
    border: "1px solid black",
    padding: "1px",
  },
  infoCell: {
    maxHeight: "5px",
  },
  innerCell: {
    padding: "0px",
    maxWidth: "40%",
  },
  formatedCell: {
    fontSize: "0.82em",
    padding: "0px",
    margin: "0px",
  },
  certificateCell: {
    fontSize: "0.82em",
    padding: "0px",
    margin: "0px",
    width: "20%",
  },
  sxPad: {
    padding: "5px",
  },
  addressCell: {
    width: "200px",
  },
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid cadetblue",
  padding: "20px",
  textAlign: "center",
  borderRadius: "5px",
};
const checkboxOptions = ["✔", "NA"];

export default function ViewCertificate(props) {
  const viewCertificateGuides = viewCertificateGuide();
  const printComponentRef = React.useRef();
  const classes = useStyles();
  const { certificateId: paramCertificateId } = useParams();
  let paramMultipleCertificateIdsArray=paramCertificateId?.split(",");
  const [finalCertificateId, setFinalCertificateId] = React.useState(paramMultipleCertificateIdsArray[0]);

  const certificateId = props.certificateIds || finalCertificateId;

  const [fetchCount, setFetchCount] = useState(0);
  const [downloadingCount, setDownloadingCount] = useState(0);
  const [instrument, setInstrument] = React.useState(null);
  const [configuration, setConfiguration] = React.useState({});
  const [clientName, setClientName] = React.useState("");
  const [clientID, setClientID] = React.useState(null);
  const [standardMasterArray, setStandardMasterArray] = React.useState([]);
  const [instrumentName, setInstrumentName] = React.useState("");
  const [requestedName, setRequestedName] = React.useState("");
  const [make, setMake] = React.useState("");
  const [calibrationProcedureNo, setCalibrationProcedureNo] =
    React.useState("");
  const [referenceStandards, setReferenceStandards] = React.useState("");
  const [lc, setLC] = React.useState("");
  const [dcNumber, setDcNumber] = React.useState("");
  const [ranges, setRanges] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [newAddressArray, setNewAddressArray] = React.useState([]);
  const [calibrationDate, setCalibrationDate] = React.useState(null);
  const [approvedDate, setApprovedDate] = React.useState(null);
  const [reviewedDate, setReviewedDate] = React.useState(null);
  const [signedDate, setSignedDate] = React.useState(null);
  const [witnessDate, setWitnessDate] = React.useState(null);
  const [dcDate, setDcDate] = React.useState(null);
  const [nextDueDate, setNextDueDate] = React.useState(null);
  const [receiptDate, setReceiptDate] = React.useState(null);
  const [poDate, setPoDate] = React.useState(null);
  const [dateOfIssue, setDateOfIssue] = React.useState("");
  const [calibrationFrequency, setCalibrationFrequency] = React.useState(null);

  const [calProcRefNo, setCalProcRefNo] = React.useState("");
  const [mfgNo, setMfgNo] = React.useState("");
  const [rangeDbData, setRangeData] = React.useState([]);
  // const [allRangeData, setRangeDataArray] = React.useState([]);
  const [specificValuesFromTable, setSpecificValuesFromTable] = React.useState(
    []
  );
  const [status, setStatus] = React.useState(-1);
  const [identificationNo, setIdentificationNo] = React.useState("");
  const [DUCID, setDUCID] = React.useState([]);
  const [calType, setCalType] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [accuracy, setAccuracy] = React.useState("");
  const [filterRangeData, setFilterRangeData] = React.useState([]);
  const [serialNo, setSerialNo] = React.useState("");
  const [selectedStandards, setSelectedStandards] = React.useState("");
  const [ulrNumber, setUlrNumber] = React.useState(null);
  const [certificateNumber, setCertificateNumber] = React.useState("");
  const [serviceReqNumber, setServiceReqNumber] = React.useState("");
  const [certificateServiceReqNumber, setCertificateServiceReqNumber] = React.useState("");
  const [jobNumber, setJobNumber] = React.useState("");
  const [workOrderNumber, setWorkOrderNumber] = React.useState("");
  const [poNo, setPoNo] = React.useState(null);
  const [startTemp, setStartTemp] = React.useState("");
  const [endTemp, setEndTemp] = React.useState("");
  const [startHumidity, setStartHumidity] = React.useState("");
  const [endHumidity, setEndHumidity] = React.useState("");
  const [models, setModel] = React.useState("");
  const [ConOfDuc, setConOfDuc] = React.useState("");
  const [calPoint, setCalPoint] = React.useState("");
  const [calMethod, setCalMethod] = React.useState("");
  const [locationOfInstrument, setLocationOfInstrument] = React.useState("");
  const [srnNo, setSrnNo] = React.useState("");
  const [atmosphericPressure, setAtmosphericPressure] = React.useState("");
  const [disciplineName, setDisciplineName] = React.useState("");
  const userType = localStorage.getItem("type");
  const [tableDisplayStatus, setTableDisplayStatus] = React.useState(1);
  const [settingList, setSettingList] = React.useState({});
  const [id, setId] = React.useState(-1);
  const [calibratedby, setCalibratedby] = React.useState("");
  const [approvedby, setApprovedby] = React.useState("");
  const [rejectedBy, setRejectedBy] = React.useState("");
  const [reviewedBy, setReviewedBy] = React.useState("");
  const [signedBy, setSignedBy] = React.useState("");
  const [witnessedBy, setWitnessedBy] = React.useState("");
  const user = [localStorage.getItem("id"), localStorage.getItem("userName")];
  const [letterHead, setLetterHead] = React.useState(true);
  const [draft, setDraft] = React.useState(false);
  const [electronicSign, setElectronicSign] = React.useState(true);
  const [extraColumns, setExtraColumns] = React.useState([]);
  const [departmet, setDepartment] = React.useState("");
  const [input, setInput] = React.useState("");
  const [operatingrange, setOperatingrand] = React.useState("");
  const [revisionNumber, setRevisionNumber] = React.useState("");
  const [amendmnetHistory, setAmendmentHistory] = React.useState("");
  const [calibrationReason, setCalibrationReason] = React.useState(1);
  const [certificateDownloadCount, setCertificateDownloadCount] =
    React.useState({});
  const [isClientSettings, setIsClientSettings] = React.useState(false);
  const [isCompanyHierarchy, setIsCompanyHierarchy] = React.useState(false);
  const [complianceStatus, setComplianceStatus] = React.useState(null);
  const [compliance, setCompliance] = React.useState(false);
  const [lastModified, setLastModified] = React.useState("");
  const [showAmendment, setShowAmendment] = React.useState(false);
  const [instrumentId, setInstrumentId] = React.useState("");
  const [deletedTableIds, setDeletedTableIds] = React.useState("");
  const [material, setMaterial] = React.useState("");
  const [assumeDensity, setassumeDensity] = React.useState("");
  const [coeffthermal, setcoeffthermal] = React.useState("");
  const [partNo, setPartNo] = React.useState("");
  const [rolesSettins, setRolesSettings] = React.useState("");
  const [calTypeSetting, setCalTypeSetting] = useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));
  const [pdfGenerated, setpdfGenerated] = React.useState(false);
	  

  const [roles, setroles] = useState([]);
  const roles_ids = roles?.map((r) => Number(r.id));
  const [analysisSelectedOption, setAnalysisSelectedOption] = React.useState({
    accepted: "",
    calibrated: "",
    limitedUse: "",
    rejected: "",
  });
  const [downloadPdfloading, setDownloadPdfloading] = React.useState(false);
  const [organizationLables, setOrganizationLables] = useState([]);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [companyHierarchy, setCompanyHierarchy] = useState("");
  const [signImg, setSignImg] = React.useState(false);
  const [tableDivide, setTableDivide] = useState(false);
  const storedDeviceInfo = localStorage.getItem("deviceInfo");

  const openErrorModal = (errorMessage, stackTrace, fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  let designation = localStorage.getItem("designation");

  const convertToBooleanOrEmpty = (value) => {
    if (value === "1") return true;
    if (value === "") return "";
    return false;
  };
  
  const copyToClipboard = () => {
    // copy current url to click on copy url button
    const qrCodeURL = `${DOMAIN}/#/certificate/print/${certificateId}`;
    const input = document.createElement("input");
    input.value = qrCodeURL;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    // show message to copy url
    const tooltip = document.createElement("div");
    tooltip.textContent = "URL copied to clipboard!";
    tooltip.style.position = "fixed";
    tooltip.style.backgroundColor = "#333";
    tooltip.style.color = "#fff";
    tooltip.style.padding = "5px";
    tooltip.style.borderRadius = "5px";
    tooltip.style.zIndex = "9999";
    tooltip.style.top = "20%";
    tooltip.style.left = "50%";
    tooltip.style.transform = "translate(-50%, -50%)";
    document.body.appendChild(tooltip);
    setTimeout(() => {
      document.body.removeChild(tooltip);
    }, 2000);
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  

  // api calls
  function fetchSettings() {
    axiosWithToken
      .get(BASE_URL + `settings?_where=(status,eq,1)`)
      .then((res) => {
        setFetchCount((prev)=> prev+1)
        let remark = null,
          complance = false,
          calTypeSetting = false,
          clientSetting = false,
          companyHierarchy = false,
          settings = res.data,
          _rolesSettings = {};
        let _settingList = {};
        settings.forEach((setting) => {
          if (setting?.keyName?.toLowerCase() === "remarks") {
            remark = setting?.value;
          } else if (
            setting?.keyName === "Compliance Status" &&
            setting?.value?.toLowerCase() === "true"
          ) {
            complance = true;
          } else if (
            setting?.keyName === "Client Settings" &&
            setting?.value?.toLowerCase() === "true"
          ) {
            clientSetting = true;
          } else if (
            setting?.keyName === "companyHierarchy" && 
            setting.status == 1
          ) {
            companyHierarchy = true;
          } else if (
            setting?.keyName === "Calibration Type" &&
            setting?.value?.toLowerCase() === "true"
          ) {
            calTypeSetting = true;
          }
          if (setting?.keyName?.startsWith("activeRole")) {
            _rolesSettings[setting?.keyName?.split("activeRole")[1]] = {
              name: setting?.value,
              id: setting?.keyName?.split("activeRole")[1],
            };
          }

          _settingList[setting?.keyName] = setting?.value;
        });
        _settingList["amendment"] = _settingList["amendment"]?.toLowerCase();
        // setSettingList(_settingList);
        setCompliance(complance);
        setIsClientSettings(clientSetting);
        setIsCompanyHierarchy(companyHierarchy);
        setCalTypeSetting(calTypeSetting);
        setRolesSettings(_rolesSettings);
      })
      .catch((err) => {
        console.error("Error fetching setting:", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "fetchSettings", "View Certificate")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }
  // todo : move to renderrangedata file
  const parseRange = (rangeVal) => {
    if (!rangeVal) {
      return "";
    }

    const tmp = rangeVal.split("||").map((range) => {
      const values = range.split("|").map((value) => value?.trim());
      const firstValueParts = values[0].split("#");
      const firstValue = firstValueParts[0].trim();
      const firstUnit = firstValueParts[1] ? firstValueParts[1].trim() : "";

      const secondValueParts = values[1]
        ? values[1].split("#")
        : [undefined, undefined];
      const secondValue = secondValueParts[0] ? secondValueParts[0].trim() : "";
      const secondUnit = secondValueParts[1] ? secondValueParts[1].trim() : "";

      let result = "";

      if (firstValue) {
        result += `${firstValue}`;
      }

      if (firstValue && secondValue) {
        result += " to ";
      }

      if (secondValue) {
        result += `${secondValue}`;
      }  
      if (firstUnit && secondUnit) {
        if (firstUnit === secondUnit) {
          result += ` ${firstUnit}`;
        } else {
          result = `${firstValue} ${firstUnit} to ${secondValue} ${secondUnit}`;
        }
      } else if (firstUnit) {
        result += ` ${firstUnit}`;
      } else if (secondUnit) {
        result += ` ${secondUnit}`;
      }

      return result;
    });

    return tmp.join(" , ");
  };
  const accuracyFormat = (value) => {
    const regex = /^\d*\.?\d+(#.*)?$/;
    return regex.test(value) ? `± ${parseRange(value)}` : parseRange(value);
  };

  function downloadAuditlog(){
    let url = BASE_URL;
    let payload = {
      clientId: clientID,
      type: "certificates",
      identifier: certificateId,
      action: "download",
      userId: user[0],
      change_date: moment(new Date().toISOString()).format("YYYY-MM-DD HH:mm:ss")
    };
    axiosWithToken
      .post(url + `audit_log`, payload)
      .then((res) => res.data)
      .catch((err) => {
        console.log("certificate downloadAudit: ", err);
      });
  }

  function fetchCertificate() {
    let url = BASE_URL;
    let sqlQuery = {
      query: `select * from certificatesView where id = ${certificateId}`,
    };
    axiosWithToken
      .post(url + `dynamic`, sqlQuery)
      .then((res) => {
        // console.log(res.data);
        setFetchCount((prev)=> prev+1);
        if (res.data.length && res.data[0] != null) {
          setLastModified(res.data[0]?.lastModified);
          setStatus(res.data[0]?.status);
          setId(res.data[0]?.id);
          setClientName(res.data[0]?.companyName);
          setClientID(res.data[0]?.clientId);
          setInstrumentName(res.data[0]?.instrumentName);
          setMake(res.data[0]?.make);
          setModel(res.data[0]?.model);
          setLC(res.data[0]?.lc);
          setRanges(parseRange(res.data[0]?.ranges));
          setReferenceStandards(res.data[0]?.isaStandard ? res.data[0]?.isaStandard : res.data[0]?.referenceStandards);
          setTableDivide(res.data[0]?.tablePartition == 1 ? true : false);
          setSelectedStandards(res.data[0]?.additionalStandardIds?.split(","));
          setCalibrationProcedureNo(res.data[0]?.calibrationProcedureNo);
          setAddress(res.data[0]?.billingAddress ? res.data[0]?.billingAddress : res.data[0]?.address);
          setDcNumber(res.data[0]?.dcNumber);
          setUlrNumber(res.data[0]?.ULRNo);
          setCertificateNumber(res.data[0]?.certificateNumber);
          setDcDate(res.data[0]?.dcDate);
          setNextDueDate(res.data[0]?.nextDueDate);
          setReceiptDate(res.data[0]?.receiptDate);
          setCalProcRefNo(res.data[0]?.calProcRefNo);
          setMfgNo(res.data[0]?.mfgNo);
          setIdentificationNo(res.data[0]?.identificationNo);
          setDUCID(res.data[0]?.DUCID);
          setCalType(res.data[0]?.calibrationType);
          setLocation(res.data[0]?.location == 1 ? "In House" :"Onsite");
          setAccuracy(res.data[0]?.accuracy);
          setSerialNo(res.data[0]?.serialNumber);
          setServiceReqNumber(res.data[0]?.serviceReqNumber);
          setCertificateServiceReqNumber(res.data[0]?.certificatesserviceReqNumber);
          setJobNumber(res.data[0]?.jobNumber);
          setWorkOrderNumber(res.data[0]?.workOrderNumber);
          setPoNo(res.data[0]?.poNumber);
          setPoDate(res.data[0]?.poDate);
          setStartTemp(res.data[0]?.startTemp);
          setEndTemp(res.data[0]?.endTemp);
          setStartHumidity(res.data[0]?.startHumidity);
          setEndHumidity(res.data[0]?.endHumidity);
          setDateOfIssue(res.data[0]?.dateOfIssue);
          setModel(res.data[0]?.model);
          setConOfDuc(res.data[0]?.ConOfDuc);
          setCalPoint(res.data[0]?.calPoint);
          setCalMethod(res.data[0]?.calMethod);
          setLocationOfInstrument(res.data[0]?.locationOfInstrument);
          setSrnNo(res.data[0]?.srnNo);
          setAtmosphericPressure(res.data[0]?.atmosphericPressure);
          setConfiguration(JSON.parse(res.data[0]?.configuration));
          setDisciplineName(res.data[0]?.name);
          setOperatingrand(parseRange(res.data[0]?.operatingRange));
          setDepartment(res.data[0]?.department);
          let extraEvvtries = res.data[0].extraColumns
            ? JSON.parse(res.data[0].extraColumns)
            : {};
          setInput(extraEvvtries["input"]);
          setMaterial(extraEvvtries["Material"]);
          setassumeDensity(extraEvvtries["Assumed Density"]);
          setcoeffthermal(extraEvvtries["Coefficient of thermal expansion"]);
          setPartNo(extraEvvtries["Part Number"]);
          setExtraColumns(
            Object.keys(extraEvvtries).length
              ? Object.entries(extraEvvtries)
              : []
          );
          setCalibrationReason(res.data[0].calibrationReason);
          setInstrumentId(res.data[0]?.instrumentId);
          setDeletedTableIds(res.data[0]?.deletedTableIds);
          res.data[0]?.specificValues
            ? setSpecificValuesFromTable(res.data[0]?.specificValues.split(","))
            : setSpecificValuesFromTable([]);
          setTableDisplayStatus(res.data[0]?.tableDisplayStatus);
          setCompanyHierarchy(res.data[0]?.companyHierarchy);

          axiosWithToken
            .get(
              BASE_URL +
                `users?_where=(id,in${
                  res.data[0]?.calibratedby
                    ? `,${res.data[0]?.calibratedby}`
                    : "" || ""
                }${
                  res.data[0]?.approvedby
                    ? `,${res.data[0]?.approvedby}`
                    : "" || ""
                }${
                  res.data[0]?.rejectedBy
                    ? `,${res.data[0]?.rejectedBy}`
                    : "" || ""
                }${
                  res.data[0]?.reviewedBy
                    ? `,${res.data[0]?.reviewedBy}`
                    : "" || ""
                }${
                  res.data[0]?.signedBy ? `,${res.data[0]?.signedBy}` : "" || ""
                }${
                  res.data[0]?.witnessBy
                    ? `,${res.data[0]?.witnessBy}`
                    : "" || ""
                })`
            )
            .then((res2) => {
              setFetchCount((prev)=> prev+1);
              // Logic to remove _c and _u if xjoin if used
              // Update replace all if xjoin members are increased in future
              // let tmp = [...res.data];
              // tmp.map((e, i) =>
              //   Object.keys(e).map(
              //     (k) =>
              //       (res.data[i][k.replaceAll("_c", "").replaceAll("_u", "")] =
              //         e[k])
              //   )
              // );
              res2.data.map((e) => {
                if (e.id == res.data[0]?.calibratedby) {
                  setCalibratedby([
                    res.data[0]?.calibratedby,
                    e.userName,
                    e.id,
                    e.companyName,
                    res.data[0]?.calibrateddesignation,
                    e?.usersign,
                  ]);
                }
                if (e.id == res.data[0]?.approvedby)
                  setApprovedby([
                    res.data[0]?.approvedby,
                    e.userName,
                    e.id,
                    e.companyName,
                    res.data[0]?.approveddesignation,
                    e?.usersign,
                  ]);
                if (e.id == res.data[0]?.rejectedBy)
                  setRejectedBy([
                    res.data[0]?.rejectedBy,
                    e.userName,
                    e.id,
                    e.companyName,
                  ]);
                if (e.id == res.data[0]?.reviewedBy)
                  setReviewedBy([
                    res.data[0]?.reviewedBy,
                    e.userName,
                    e.id,
                    e.companyName,
                    e?.usersign,
                  ]);
                if (e.id == res.data[0]?.signedBy)
                  setSignedBy([
                    res.data[0]?.signedBy,
                    e.userName,
                    e.id,
                    e.companyName,
                    e?.usersign,
                  ]);
                if (e.id == res.data[0]?.witnessBy)
                  setWitnessedBy([
                    res.data[0]?.witnessBy,
                    e.userName,
                    e.id,
                    e.companyName,
                    e?.usersign,
                  ]);
              });
            });

          setCalibrationDate(res.data[0].calibrationDate);
          setApprovedDate(res.data[0]?.approvedDate);
          setReviewedDate(res.data[0]?.reviewedDate);
          setSignedDate(res.data[0]?.signedDate);
          setWitnessDate(res.data[0]?.witnessDate);

          setCalibrationFrequency(res.data[0]?.calFrequency == "Not Required" ? "-" : res.data[0]?.calFrequency);
          setRevisionNumber(res.data[0]?.revisionNumber);
          let tmpSplt = res.data[0]?.amendment
            ? res.data[0]?.amendment.split("\n")
            : [];
          setAmendmentHistory(
            tmpSplt.filter((_, i) => i === tmpSplt.length - 1)
          );
          setRequestedName(
            res.data[0]?.requestedname ? res.data[0]?.requestedname : ""
          );
          setComplianceStatus(res.data[0]?.complianceStatus);

          fetchInstrument(res.data[0]?.instrumentId);

          axiosWithToken
            .get(
              url +
                `certificateStaticReadings?_where=(certificateId,eq,${certificateId})`
            )
            .then((response) => {
              setFetchCount((prev)=> prev+1);
              if (response?.data) {
                let standardIds = response?.data
                  ?.map((e) => e.standardRanges)
                  .filter(Boolean);
                const selectedStandardIds = [];
                standardIds.forEach((str) => {
                  str.split(",").forEach((sub) => {
                    const num = parseInt(sub.split(":")[0]);
                    if (!selectedStandardIds.includes(num)) {
                      selectedStandardIds.push(num);
                    }
                  });
                });

                if (selectedStandardIds) {
                  let combineArray = [...new Set(selectedStandardIds)];
                  axiosWithToken
                    .get(url + `standards?_where=(id,in,${combineArray.toString()})`)
                    .then((res) => {
                      setStandardMasterArray(res.data)
                      setFetchCount((prev)=> prev+1)
                    })
                    .catch((err) => console.log(err));
                }
                setFetchCount((prev)=> prev+1)
              }
            })
            .catch((err) => console.log(err));

            if(res.data[0]?.billingId){
              axiosWithToken
              .get(BASE_URL + `clients/${res.data[0]?.billingId}`)
              .then((res) => {
                let billingName= res.data[0]?.companyName;
                setClientName(billingName ? billingName : res.data[0]?.companyName);
              })
              .catch((err) => console.log(err));
            }

        }
      })
      .catch((err) => {
        console.log("certificate data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(
                  err.message,
                  "fetchCertificate",
                  "View Certificate"
                )
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }

  function fetchInstrument(instrumentId) {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `instruments?_where=(id,eq,${instrumentId})`)
      .then((res) => {
        setFetchCount((prev)=> prev+1);
        setInstrument(res.data);
      })
      .catch((err) => {
        console.log("certificate tables data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "fetchInstrument", "View Certificate")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }

  function certificateCount() {
    let data = {
      query: `SELECT * FROM certificateAnalytics WHERE certificateId = ${certificateId} `,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        // setFetchCount((prev)=> prev+1)
        setCertificateDownloadCount(res.data[0]);
      })
      .catch((err) => {
        console.log("certificateAnalytics tables data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(
                  err.message,
                  "certificateCount",
                  "View Certificate"
                )
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }


  useEffect(() => {
    const fetchClientSetting = async () => {
      try {
        const AllSettings = await allSettings(
          isClientSettings ? clientID : undefined,
          (isClientSettings && isCompanyHierarchy) ? companyHierarchy : undefined
        );
        let _settingList = {};
        AllSettings.forEach((setting) => {
          _settingList[setting?.keyName] = setting.value;
        });
        setSettingList(_settingList);

        const footerRoles = AllSettings?.filter(
          (el) =>
            el.keyName?.includes("role") &&
            el.value !== "false" &&
            !el.keyName?.includes("_PossibleFields")
        )
          ?.map((item) => ({
            name: item.value,
            id: item.keyName.charAt(item.keyName.length - 1),
            label: item.value,
            key: item.keyName,
          }))
          ?.sort((a, b) => a.id - b.id);

        let OrgLables = footerRoles?.filter((el) => el.key.includes("Label"));
        let footerRoleFilter = footerRoles?.filter(
          (el) => !el.key.includes("Label")
        );

        setOrganizationLables(OrgLables);
        setroles(footerRoleFilter);
        setFetchCount((prev)=> prev+1);
      } catch (err) {
        console.error("Error fetching data:", err);
        let trace = new Error().stack;
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, trace, "View Certificate")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      }
    };

    fetchClientSetting();
  }, [clientID, isClientSettings, companyHierarchy, isCompanyHierarchy, certificateNumber]);

  function getOpinionAndAnalysisList() {
    let url = BASE_URL;
    const payload = {
      query: `SELECT * FROM opinionAndAnalysis WHERE certificateId = ${certificateId}`,
    };
    axiosWithToken
      .post(url + "dynamic", payload)
      .then((res) => {
        if (res.data.length > 0) {
          setFetchCount((prev)=> prev+1);
          const parsedData = {
            ...res.data[0],
            accepted: convertToBooleanOrEmpty(res.data[0].accepted),
            limitedUse: convertToBooleanOrEmpty(res.data[0].limitedUse),
            calibrated: convertToBooleanOrEmpty(res.data[0].calibrated),
            rejected: convertToBooleanOrEmpty(res.data[0].rejected),
          };
          setAnalysisSelectedOption(parsedData);
        }
      })
      .catch((err) => {
        console.log("Something Went Wrong!");
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(
                  err.message,
                  "getOpinionAndAnalysisList",
                  "View Certificate"
                )
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }

  useEffect(() => {
    fetchCertificate();
    fetchSettings();
    certificateCount();
    getOpinionAndAnalysisList();
  }, [finalCertificateId]);


  useEffect(() => {


    if (fetchCount > 6 && pdfGenerated && paramMultipleCertificateIdsArray.length > 1 && downloadingCount < paramMultipleCertificateIdsArray.length) {
     const timer = setTimeout(() => {
        generatePDF().then(() => {
          setFetchCount(0);
         if (downloadingCount+1 < paramMultipleCertificateIdsArray.length) {
            setFinalCertificateId(paramMultipleCertificateIdsArray[downloadingCount+1]);
          }
          setDownloadingCount((prev) => prev + 1);
          // pdfGenerated = false;
        });
      }, 1000);
      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [certificateNumber, fetchCount, pdfGenerated]);


  useEffect(() => {
if(!paramMultipleCertificateIdsArray.length > 1){
    if (userType == 3 && approvedby.length == 0) {
      setDraft(true);
      setLetterHead(false);
    } else {
      setDraft(false);
      setLetterHead(true);
    }
  }
  }, [userType, approvedby]);


  var refresh = () => {
    window.location.reload(false);
  };

  const generateDynamicHeader = async (
    ulrNumber,
    certificateNumber,
    pageNo,
    totalPages
  ) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div id="responsivepdfheader">
      <table  style="width:100%;">
        <tr>
          <td style="padding:1px 5px;font-family: 'Poppins, sans-serif';height:40px;text-align: left;">
            <b style="font-size:16px;">ULR Number</b> 
          </td>
          <td style="padding:1px 5px;font-family: 'Poppins, sans-serif';height:40px;text-align: left;">
            <b style="font-size:15px;">${ulrNumber}</b>
          </td>
          <td style="padding:1px 5px;font-family: 'Poppins, sans-serif';height:40px;text-align: left;">
            <b style="font-size:16px;">Certificate Number</b>
          </td>
          <td style="padding:1px 5px;font-family: 'Poppins, sans-serif';height:40px;text-align: left;">
          <b style="font-size:15px;">${certificateNumber}</b>
        </td>
        </tr>
        <tr>
          <td style="padding:1px 5px;font-family: 'Poppins, sans-serif';height:40px;text-align: left;">
            <b style="font-size:16px;">Date of Calibration</b> 
          </td>
          <td style="padding:1px 5px;font-family: 'Poppins, sans-serif';height:40px;text-align: left;">
            <b style="font-size:15px;">${formatDateByClient(calibrationDate) || "\u00A0"}</b>
          </td>
          <td style="padding:1px 5px;font-family: 'Poppins, sans-serif';height:40px;text-align: left;">
            <b style="font-size:16px;">Recommended due date</b>
          </td>
          <td style="padding:1px 5px;font-family: 'Poppins, sans-serif';height:40px;text-align: left;">
          <b style="font-size:15px;">${calibrationFrequency == "-" ? "-" : formatDateByClient(nextDueDate) || "\u00A0"}</b>
        </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
    const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${
        calType == 1
          ? UnitechHeader
          : calType == 2
          ? UnitechHeaderIas
          : UnitechHeaderNonNabl
      } style="width:100%; height:170px;"/>
    </div>`;
    
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.4 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
};

  const generateDraftImage = async () => {
    let htmlString = `
    <div>
        ${
          letterHead
            ? `<img src=${LogoImage} style="width:70%; height:340px; opacity:0.2"/>`
            : `<img src=${WithoutLogoImage} style="width:70%; height:1px; opacity:0.1;"/>`
        }
        ${
          draft
            ? `<img src=${DraftImage} style="width:100%; height:1200px; opacity:0.2"/>`
            : ""
        }
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, scale: 1 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateDynamicFooter = async (
    calibratedby,
    approvedby,
    calibrationDate
  ) => {
    let footers = [
      ...roles,
      {
        name: "",
        id: 3,
        by: "",
        label: " ",
        align: "center",
      },
    ];
    footers.map((e, i) => {
      if (e.id == 1) {
        footers[i].imgSrc = calibratedby[5];
        footers[i].by = calibratedby[1];
        footers[i].date = calibrationDate;
        footers[i].companyName = calibratedby[3];
        footers[i].label = organizationLables?.[0]
          ? organizationLables[0]?.name
          : "Calibrated by";
        footers[i].name = calibratedby[4] ? calibratedby[4] : footers[i].name;
        } else if (e.id == 2) {
        footers[i].imgSrc = approvedby[5];
        footers[i].by = approvedby[1];
        footers[i].date = approvedDate;
        footers[i].companyName = approvedby[3];
        footers[i].label = organizationLables?.[1]
          ? organizationLables[1]?.name
          : "Reviewed & Approved by";
        footers[i].name = approvedby[4] ? approvedby[4] : footers[i].name;
        } else if (e.id == 3) {
        footers[i].imgSrc = reviewedBy[4];  
        footers[i].by = reviewedBy[1];
        footers[i].date = reviewedDate;
        footers[i].companyName = reviewedBy[3];
        footers[i].name = clientName;
      } else if (e.id == 4) {
        footers[i].imgSrc = signedBy[4];
        footers[i].by = signedBy[1];
        footers[i].date = signedDate;
        footers[i].companyName = signedBy[3];
        footers[i].name = clientName;
      } else if (e.id == 5) {
        footers[i].imgSrc = witnessedBy[4];
        footers[i].by = witnessedBy[1];
        footers[i].date = witnessDate;
        footers[i].companyName = witnessedBy[3];
        footers[i].name = clientName;
      }
    });
    if (footers.length <= 4) {
      footers = makeChunks(footers, footers.length == 4 ? 4 : 3);
    } else if (footers.length == 5) {
      footers = [
        [footers[0], footers[1]],
        [footers[2], footers[3], footers[4]],
      ];
    }
    footers.map((row, i) => {
      footers[i][0].align = "left";
      if (row.length === 3) {
        footers[i][1].align = "left";
      }
      footers[i][row.length - 1].align = "left";
    });
    let footerString = `
    <div id="responsivepdfheader">
      ${footers
        .map(
          (row, index) => `
          <table style="width:100%;margin-bottom:5px">
          <tr>
            ${row
              .map((column) =>
                electronicSign
                  ? `
                  <td style="vertical-align:top;text-align: ${
                    column.align
                  };font-family: 'Poppins, sans-serif';line-height:30px;width:${
                      column.id == 3 ? "20" : "40"
                    }%">
                    <div style="width: 100%;display:flex;flex-direction:column;">
                    <div style="padding:5px 10px;">
                    <b style="font-size:16px;line-height:5px;text-transform: 'uppercase'">${
                      column.label
                    }</b>                    
                    </div>
                    <div style="border-top:${
                      column.id !== 3 ? "1px solid black;" : "none;"
                    }border-bottom:none;padding:0px 10px;display:flex;align-items:center;width:100%;">
                    ${
                      column.id !== 3
                        ? `
                          <div style="width:50%;border-right:1px solid black;">
                            <b style="font-size:16px;">Identification Number</b>
                          </div>
                          <div style="width:55%;vertical-align: text-top;">
                            <b style="font-size:14px;padding-left:5px;">${
                              column.by ? column.by : "\u00A0"
                            }</b>
                          </div>`
                        : `<div style="width:100%;height:50px;display:flex;justify-content:center;align-items:center;">
                        <b style="font-size:15px;padding-left:5px;">Digitally Signed</b>
                        </div>`
                    }                          
                    </div>
                    </div>    
                  </td>`
                  : `
                  <td style="vertical-align:top;text-align: ${
                    column.align
                  };font-family: 'Poppins, sans-serif';line-height:30px;width:${
                      100 / row.length
                    }%">
                    <div style="width: 100%;disply:flex;flex-direction:column;">
                    <div style="padding:5px 10px;">
                    <b style="font-size:17px;text-transform: 'uppercase'">${
                      column.label
                    }</b>                    
                    </div>
                    <div style="border-top:${
                      column.id !== 3 ? "1px solid black;" : "none;"
                    }border-bottom:none;padding:0px 10px;display:flex;align-items:center;width:100%;">
                    ${
                      column.id !== 3
                        ? `
                          <div style="width:50%;border-right:1px solid black;">
                            <b style="font-size:16px;">Identification </b>
                          </div>
                          <div style="width:50%;">
                            <b style="font-size:14px;padding-left:5px;">${
                              column.by ? column.by : "\u00A0"
                            }</b>
                          </div>`
                        : `<div style="width:100%;text-align:center;background: white;border:1px solid white;">
                        ${signImg ? 
                        `<div style="border:1px solid white;padding:5px 15px;height:50px;background: white;">
                          ${approvedby[5] ? `<img src="${approvedby[5]}" style="width:30%;background: white;height:100%;border:none;"/>` : '&nbsp;'}
                        </div>`
                        : '&nbsp;'}                        
                        </div>`
                    }                          
                    </div>
                    </div>   
                  </td>`
              )
              .join("")}
          </tr></table>`
        )
        .join("")}
    </div>`;

    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = footerString;
    let image = await toPng(htmlNode, { quality: 1, scale: 1 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return [img, footers.length];
  };
  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.4 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };
  const generateDynamicFooterEnd = async (pageNo, totalPages) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage} of ${formattedTotalPages}`;

    let endOfCertificateText = ""; // Initialize the text to empty string
    if (pageNo === totalPages) { // Display the text only on the last page
        endOfCertificateText = `<b style="font-size:18px;font-family: 'Poppins, sans-serif';">- End of Calibration Certificate -</b>`;
    }

    let htmlString = `    
    <div id="responsivepdfheader">
      <table style="width:100%;">
        <tr>
          <td style="border:none;width:33.33%;padding:1px 5px;height:40px;text-align: left;">
            <b style="font-size:18px;font-family: 'Poppins, sans-serif';"></b> 
          </td>
          <td style="border:none;width:33.33%;padding:1px 5px;height:40px;text-align: center;">
            ${endOfCertificateText}
          </td>
          <td style="border:none;width:33.33%;padding:1px 5px;height:40px;text-align: right;">
            <span style="font-size:18px;font-family: 'Poppins, sans-serif';">Page No: ${pageRange}</span>
          </td>
        </tr>
      </table>
    </div>
    `;
    
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
};

  var refresh = () => {
    window.location.reload(false);
  };
  const generatePDF = async () => {
    setDownloadPdfloading(true);
    try {
      const element = printComponentRef.current;
      const [_, marginNumber] = await generateDynamicFooter(
        calibratedby,
        approvedby,
        calibrationDate
      );
      let footerHeight = 80;
      let footerPos = [0, 715];
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [143, 1, 37 + marginNumber * footerHeight, 1],
          filename: `certificate_${certificateId}.pdf`,
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 4,
            letterRendering: true,
            useCORS: true,
          },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
            compress: true, 
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      // Load the watermark image
      const watermarkImage = await generateDraftImage();

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("Poppins, sans-serif");

        // Add watermark image at the back of the page
        const pageWidth1 = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = 400;
        const imgHeight =
          (imgWidth * watermarkImage.height) / watermarkImage.width;
        const x1 = (pageWidth1 - imgWidth) / 2;
        const y1 = (pageHeight - imgHeight) / 2;
        pdf.addImage(watermarkImage.src, "PNG", x1, y1, imgWidth, imgHeight);

        if (letterHead) {
          let image = await generateLetterHeadHeader();

          pdf.addImage(image, 0, 1, 595, 95);

          image = await generateLetterHeadFooter();
          pdf.addImage(image, 0, 785, 595, 60);
        }

        let image = await generateDynamicHeader(
          ulrNumber,
          `${certificateNumber}${
            settingList["amendment"] === "true" && revisionNumber > 0
              ? `/AMD-${revisionNumber}`
              : ""
          }`,
          i,
          totalPages - 1
        );
        pdf.addImage(image, 0, 80, 595, 70);
        if (i !== totalPages) {
          let height;
          [image, height] = await generateDynamicFooter(
            calibratedby,
            approvedby,
            calibrationDate
          );

          pdf.addImage(
            image,
            footerPos[0],
            height > 1
              ? footerPos[1] - (height - 1) * footerHeight
              : footerPos[1],
            595,
            height * footerHeight
          );

          if (
            electronicSign &&
            settingList["electronicSign"] &&
            settingList["electronicSign"].toLowerCase() === "true"
          ) {
            pdf.setFontSize(9);
            const text =
              "This certificate is electronically signed and does not required physical signature";
            const textWidth =
              (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) /
              pdf.internal.scaleFactor;
            const pageWidth = pdf.internal.pageSize.getWidth();
            const x = (pageWidth - textWidth) / 2;
            pdf.text(text, x, 768);
          }
        }
        // if (i === totalPages) {

          image = await generateDynamicFooterEnd(
            i,
            totalPages
          );
          pdf.addImage(image, 0, 755, 595, 57);

          // pdf.setFontSize(10);
          // const text = "- End of Calibration Certificate -";
          // const text1 =
          //   certificateDownloadCount?.certificateDownloadCounter > 0
          //     ? `Duplicate copy.${certificateDownloadCount.certificateDownloadCounter}`
          //     : ".";
          
          // const textWidth =
          // (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) /
          // pdf.internal.scaleFactor;
          // const pageWidth = pdf.internal.pageSize.getWidth();
          // const x = (pageWidth - textWidth) / 2;
          // pdf.text(text, x, 787);            
          // {
            //   settingList["Duplicate copy"] &&
            //     settingList["Duplicate copy"].toLowerCase() === "true" &&
            //     pdf.text(text1, pageWidth - 83, 787);
            // }
            // pdf.setFontSize(10);
          // }
          // const formattedCurrentPage = i.toString().padStart(2, "0");
          // const formattedTotalPages = totalPages.toString().padStart(2, "0");
          // const pageRange = `${formattedCurrentPage} of ${formattedTotalPages}`;
          // const text2 = `Page No: ${pageRange}`;
          // const pageWidth = pdf.internal.pageSize.getWidth();
          // pdf.text(text2, pageWidth - 93, 787);           

        // border add for whole certificate
        const borderWidth1 = 0.6;
        const leftMargin1 = 5;
        const borderX1 = leftMargin1;
        const borderY1 = 2;
        const rightMargin1 = 595 - leftMargin1 * 0.95;
        pdf.setLineWidth(borderWidth1);
        pdf.rect(borderX1, borderY1, rightMargin1 - leftMargin1, 836);
      }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Certificate_${clientName}_${certificateNumber}-CC.pdf`;
      a.click();
      // pdf.save();
      countOfCertificatesAnalytics([certificateId]);
      if (
        props.isPrint != true &&
        settingList["Duplicate copy"] &&
        settingList["Duplicate copy"].toLowerCase() === "true"
      ) {
        setTimeout(() => {
          refresh();
        }, 500);
      }
      setDownloadPdfloading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownloadPdfloading(false);
    }
  };

  // print view
  const dataArray = [
    ulrNumber,
    certificateNumber,
    calibrationDate,
    JSON.stringify(settingList),
    revisionNumber,
    Array.isArray(calibratedby) ? calibratedby.join(",") : calibratedby,
    Array.isArray(approvedby) ? approvedby.join(",") : approvedby,
    approvedDate,
    Array.isArray(reviewedBy) ? reviewedBy.join(",") : reviewedBy,
    reviewedDate,
    clientName,
    Array.isArray(signedBy) ? signedBy.join(",") : signedBy,
    signedDate,
    Array.isArray(witnessedBy) ? witnessedBy.join(",") : witnessedBy,
    witnessDate,
    JSON.stringify(roles),
    JSON.stringify(organizationLables),
  ];

  const formatDateByClient = (date, time) => {
    if (!date || !moment(date).isValid()) {
      return "\u00A0"; // Return non-breaking space if date is invalid or empty
    }
    const dateFormat =
      settingList["Date format(word)"] &&
      settingList["Date format(word)"].toString().toLowerCase() === "true"
        ? "DD MMM YYYY"
        : "DD.MM.YYYY";

    if (time) {
      return moment(date).format(`${dateFormat}, HH:mm`);
    } else {
      return moment(date).format(dateFormat);
    }
  };  

  useEffect(async () => {
    let splitAddress = address?.split(",");
    let newAddressArray = await fetchAddresses(splitAddress?.[0], "0");
    setNewAddressArray(newAddressArray);
  }, [address]);
  const concatenatedData = dataArray.join("&&");
  const printView = () => {
    return (
      // <>
      <div id="printOnly2" ref={printComponentRef}>
        {certificatefirstpage()}
        <span style={{ pageBreakBefore: "always" }} />
        <table style={{ width: "100%" }}>
          <tbody className="page" data-id={concatenatedData}>
            <tr>
              <td style={{ border: "none" }}>
                <div
                  className="content"
                  style={{
                    fontSize: "11px",
                    margin: "20px",
                    marginTop: "0px",
                    marginBottom: "0px",
                    padding: "5px 0px",
                    // border: "1px solid black",
                  }}
                >
                  <Table>
                    {customerDetailsTable()}
                    <TableRow>
                      <TableCell colSpan={2} align="left" style={{padding:"5px 0px"}}>
                        <b>Description of the Unit Under Calibration (UUC)</b>
                      </TableCell>
                    </TableRow>
                    {unitCalibrationDetails()}
                    <table
                      className="standardUnitTable single-border-table"
                      align="center"
                      style={{ marginBottom: "5px" }}
                    >
                      <tr>
                        <td>
                          <b>Condition of unit under calibration on receipt</b>
                        </td>
                        <td>{ConOfDuc || "\u00A0"}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Method used - RPLC Work Instruction Number</b>
                        </td>
                        <td> {calProcRefNo ? calProcRefNo : calibrationProcedureNo || "\u00A0"}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Reference Standard (International / National)</b>
                        </td>
                        <td>{referenceStandards || "\u00A0"}</td>
                      </tr>
                    </table>
                    <Table
                      className={classes.table}
                      style={{ marginBottom: "5px" }}
                    >
                      <TableRow>
                        <TableCell
                          style={{
                            textAlign: "left",
                            padding: "5px",
                            borderRight: "1px solid black",
                          }}
                          rowSpan={3}
                        >
                          <b>Environmental details</b>
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "left",
                            padding: "5px",
                            borderBottom: "1px solid black",
                            borderRight: "1px solid black",
                          }}
                        >
                          <b>Temperature</b> (°C)
                        </TableCell>
                        <TableCell
                          rowSpan={2}
                          style={{
                            textAlign: "left",
                            padding: "5px",
                            borderBottom: "none",
                            borderRight: "1px solid black",
                          }}
                        >
                          <b>Start</b>
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "left",
                            padding: "5px",
                            borderBottom: "1px solid black",
                            borderRight: "1px solid black",
                          }}
                        >
                          {startTemp?.includes("°C")
                            ? startTemp?.replace("#", " ")
                            : startTemp
                            ? startTemp + " °C"
                            : "\u00A0"}
                        </TableCell>
                        <TableCell
                          rowSpan={2}
                          style={{
                            textAlign: "left",
                            padding: "5px",
                            borderBottom: "none",
                            borderRight: "1px solid black",
                          }}
                        >
                          <b>End</b>
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "left",
                            padding: "5px",
                            borderBottom: "1px solid black",
                            borderRight: "1px solid black",
                          }}
                        >
                          {endTemp?.includes("°C")
                            ? endTemp?.replace("#", " ")
                            : endTemp
                            ? endTemp + " °C"
                            : "\u00A0"}
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "left",
                            padding: "5px",
                            borderBottom: "none",
                            borderRight: "1px solid black",
                          }}
                          rowSpan={2}
                        >
                          <b>Difference</b>
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "left",
                            padding: "5px",
                            borderBottom: "1px solid black",
                            borderRight: "none",
                          }}
                        >
                          {" "}
                          {
                            // Calculate the absolute temperature difference
                            isNaN(parseFloat(startTemp)) ||
                            isNaN(parseFloat(endTemp))
                              ? "\u00A0"
                              : Math.abs(
                                  parseFloat(startTemp) - parseFloat(endTemp)
                                ).toFixed(1) + " °C"
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            textAlign: "left",
                            padding: "5px",
                            borderBottom: "none",
                            borderRight: "1px solid black",
                          }}
                        >
                          <b>Relative Humidity (%RH)</b>
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "left",
                            padding: "5px",
                            borderBottom: "none",
                            borderRight: "1px solid black",
                            textTransform: "uppercase",
                          }}
                        >
                          {startHumidity?.includes("%RH")
                            ? startHumidity?.replace("#", " ")
                            : startHumidity
                            ? startHumidity + " %RH"
                            : "\u00A0"}
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "left",
                            padding: "5px",
                            borderBottom: "none",
                            borderRight: "1px solid black",
                            textTransform: "uppercase",
                          }}
                        >
                          {endHumidity?.includes("%RH")
                            ? endHumidity?.replace("#", " ")
                            : endHumidity
                            ? endHumidity + " %RH"
                            : "\u00A0"}
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "left",
                            padding: "5px",
                            borderBottom: "none",
                            borderRight: "none",
                            textTransform: "uppercase",
                          }}
                        >
                          {
                            // Calculate the absolute humidity difference
                            isNaN(parseFloat(startHumidity)) ||
                            isNaN(parseFloat(endHumidity))
                              ? "\u00A0"
                              : Math.abs(
                                  parseFloat(startHumidity) -
                                    parseFloat(endHumidity)
                                ).toFixed(1) + " %RH"
                          }
                        </TableCell>
                      </TableRow>
                      {atmosphericPressure && (
                        <TableRow>
                          <TableCell
                            style={{
                              textAlign: "left",
                              padding: "5px",
                              borderBottom: "1px solid black",
                              borderRight: "1px solid black",
                            }}
                          >
                            <b>Pressure (mbar)</b>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              padding: "5px",
                              borderBottom: "1px solid black",
                              borderRight: "1px solid black",
                            }}
                            colSpan={2}
                          >
                            {atmosphericPressure}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              padding: "5px",
                              borderBottom: "1px solid black",
                              borderRight: "1px solid black",
                            }}
                            colSpan={3}
                          >
                            <b>Thermal Stabilization hours</b>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              padding: "5px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            1 hour
                          </TableCell>
                        </TableRow>
                      )}
                    </Table>
                    <TableRow>
                      <TableCell colSpan={2} align="left" style={{padding:"5px 0px"}}>
                        <b>
                          {" "}
                          Details of Traceability - Master Equipment / Standard
                          used for Calibration
                        </b>
                      </TableCell>
                    </TableRow>
                    {masterDataTable()}
                  </Table>
                  <p style={{pageBreakBefore: "always"}}/>
                  <div>
                    <div
                      style={{ textAlign: "left" }}
                    >
                      <div style={{ marginBottom: "5px", marginTop: "5px" ,fontSize: "12px", pageBreakInside:"avoid" }}>
                        <b style={{marginTop:"10px"}}>Discipline : </b>
                        {disciplineName}
                      </div>
                      <div className="row">
                        <RenderStaticTables
                          tableDisplayStatus={tableDisplayStatus}
                          configuration={configuration}
                          certificateId={certificateId}
                          instrumentId={instrumentId}
                          deletedTableIds={deletedTableIds}
                          tableDivide={tableDivide}
                        />
                      </div>
                    </div>
                  </div>
                  {settingList?.["Opinion And Analysis Table"] === "true" &&
                    opinionAndAnalysis()}
                  <div className="row">
                    <div
                      className="col-12"
                      style={{ pageBreakInside: "avoid" }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            flex: "1",
                          }}
                        >
                          <div className="text-start" style={{}}>
                            <pre
                              style={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "10px",
                                marginBottom: "0px",
                                padding: "0",
                                lineHeight: 1,
                                whiteSpace: "pre-wrap",
                                pageBreakInside: "avoid",
                              }}
                            >
                              <div
                                style={{
                                  // border: "1px solid black",
                                  padding: "0px",
                                  marginTop: "5px",
                                }}
                              >
                                <b
                                  style={{
                                    float: "left",
                                    fontSize: "12px",
                                    fontFamily: "Poppins, sans-serif",
                                    marginBottom:"5px"
                                  }}
                                  className="standardUnitTable"
                                >
                                  Remarks :
                                </b>
                                <span
                                  style={{ fontFamily: "Poppins, sans-serif",fontSize: "12px",}}
                                >
                                  {settingList["Compliance Status"] &&
                                  settingList[
                                    "Compliance Status"
                                  ].toLowerCase() === "true"
                                    ? `The results ${
                                        complianceStatus == 1 ? "" : "doesn't"
                                      } comply with acceptance criteria\n`
                                    : ""}
                                  {calTypeSetting
                                    ? `Reason For Calibration : ${
                                        (
                                          settingList?.[
                                            "CalibrationTypeOptions"
                                          ]?.split(",") || []
                                        )
                                          .map((label, index) =>
                                            index === calibrationReason - 1
                                              ? label
                                              : null
                                          )
                                          .filter(
                                            (label) => label !== null
                                          )?.[0] || ""
                                      }\n`
                                    : ""}
                                  {`${
                                    configuration?.remark || ""
                                  }\n`}
                                  {settingList["amendment"] === "true"
                                    ? `${amendmnetHistory}`
                                    : ""}
                                </span>
                              </div>
                            </pre>
                          </div>
                        </div>
                        {/* <div
                          style={{
                            textAlignLast: "center",
                            padding: "0px 10px",
                            marginTop: "5px",
                          }}
                        >
                          <QRCode
                            value={`${DOMAIN}/#/certificate/print/${certificateId}`}
                            align="center"
                            size={60}
                          />
                        </div> */}
                      </div>
                      <br />
                      {/* <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {settingList["lastModified"] &&
                        settingList["lastModified"].toLowerCase() === "true"
                          ? `\nPrint At : ${formatDateByClient(new Date(), true)}`
                          : ""}
                      </div> */}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <span style={{ pageBreakBefore: "always" }} />
        {certificatelastpage()}
        <div style={{ width: "100%" }} id="header-section"></div>
      </div>
      // </>
    );
    function certificatefirstpage() {
      return (
        <div
          style={{
            width: "100%",
            margin: "0 auto",
            padding: "0px 0px",
            // border: "1px solid black",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // fontFamily: "Poppins, sans-serif"
          }}
        >
          <div
            style={{
              width: "95.5%",
              margin: "0 auto",
            }}
          >
            <br />
            <h3
              style={{
                fontWeight: "600",
                fontSize: "23px",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {disciplineName}
            </h3>
            <h4
              style={{
                fontWeight: "600",
                fontSize: "21px",
                fontFamily: "Poppins, sans-serif",
                margin: "10px 0px",
              }}
            >
              Certificate of Calibration
            </h4>
            <h5
              style={{
                margin: "5px 0px",
                fontSize: "21px",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              <b>For</b>
            </h5>
            <h5
              style={{
                margin: "5px 0px",
                fontSize: "21px",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              <b>{requestedName.length > 0 ? requestedName : instrumentName}</b>
            </h5>
            <br />
            <div
              style={{
                marginTop: "5px",
                color: "black",
                textAlign: "justify",
                padding: "5px 5px",
              }}
            >
              <p
                style={{
                  color: "black",
                  fontFamily: "Poppins, sans-serif",
                  // marginTop: "5px",
                  fontSize: "13px",
                }}
              >
                Jayasree Reva Phoenix Metrology (JRPM) certifies that the
                equipment has been calibrated / tested in accordance with a
                quality system registered to ISO 9001:2015 and conform to
                ISO/IEC 17025:2017 JRPM applicable procedures.
              </p>
              <p
                style={{
                  color: "black",
                  fontFamily: "Poppins, sans-serif",
                  marginTop: "5px",
                  fontSize: "13px",
                }}
              >
                The measurement expanded uncertainties is expressed at 95%
                confidence level with a coverage factor equal to two (k=2).
              </p>
              <p
                style={{
                  color: "black",
                  fontFamily: "Poppins, sans-serif",
                  marginTop: "5px",
                  fontSize: "13px",
                }}
              >
                Jayasree Reva Phoenix Metrology (JRPM) measurement standards
                are traceable through unbroken chain of comparison to the
                India's National Physical Laboratory and / or to National
                Standards. Calibration of all instruments, meters used are
                traceable to National Standards and their calibration are
                current.
              </p>
              <p
                style={{
                  color: "black",
                  fontFamily: "Poppins, sans-serif",
                  marginTop: "5px",
                  fontSize: "13px",
                }}
              >
                This calibration report contains the results of calibration and
                / or other relevant data about the calibration. This report
                applies only to the item identified and shall not be reproduced
                except in full, without specific written approval by Jayasree Reva Phoenix Metrology (JRPM).
              </p>
              <p
                style={{
                  color: "black",
                  fontSize: "13px",
                  fontFamily: "Poppins, sans-serif",
                  marginTop: "5px",
                }}
              >
                No statement of compliance with specifications is made or
                implied on this report. However, the results are reviewed to
                establish where any measurement results exceeded the
                manufacturers specifications and communicate results by means of
                this report.
              </p>
              <div
                style={{
                  padding: "0px",
                  marginTop: "10px",
                  display:"flex",
                  justifyContent:"space-between"                
                }}
              >
                <div>
                <QRCode
                  value={"https://nablwp.qci.org.in/CertificateScopenew?x=W8wxwhmkH5poKpKreY0yrA==&a=NTA4Mzg="}
                  align="center"
                  size={90}
                />
                  <p style={{color: "black",marginTop:"2px", fontSize:"10px", fontWeight:"600", fontFamily: "Poppins, sans-serif",
}}>Lab QR Code</p>
                </div>
                <div>
                <QRCode
                  value={`${DOMAIN}/#/certificate/print/${certificateId}`}
                  align="center"
                  size={90}
                  />
                  <p style={{color: "black",marginTop:"2px", fontSize:"10px", fontWeight:"600", fontFamily: "Poppins, sans-serif",
}}>Certificate QR Code</p>
                </div>
              </div>
            </div>
            <br />
            <table
              style={{ marginTop: "17px" }}
              className="standardUnitTable"
              align="center"
            >
              <tr>
                <td style={{ borderBottom: "none", borderRight: "none" }}>
                  <b>Calibration Procedure</b>
                </td>
                <td style={{ borderBottom: "none", borderRight: "none" }}>
                  <b>As Received Conditions</b>
                </td>
                <td style={{ borderBottom: "none" }}>
                  <b>As Shipped Condition</b>
                </td>
              </tr>
              <tr>
                <td style={{ borderRight: "none" }}>
                  {calProcRefNo ? calProcRefNo : calibrationProcedureNo || "\u00A0"}
                </td>
                <td style={{ borderRight: "none" }}>{ConOfDuc || "\u00A0"}</td>
                <td>Cleaned & Calibrated</td>
              </tr>
            </table>
          </div>
        </div>
      );
    }
    function certificatelastpage() {
      return (
        <div
          style={{
            width: "97%",
            margin: "0 auto",
            padding: "6px 6px 0px 6px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // border: "1px solid black",
            fontSize: "12px",
            // fontFamily: "Poppins, sans-serif"
          }}
        >
          <div
            style={{
              margin: "0 auto",
              padding: "0px 10px",
            }}
          >
            <h4
              style={{
                fontWeight: "600",
                fontSize: "15px",
                fontFamily: "Poppins, sans-serif",
                marginBottom: "5px",
              }}
            >
              ANNEXURE TO CALIBRATION CERTIFICATE
            </h4>
            <ol
              style={{
                width: "100%",
                margin: "0 auto",
                listStyleType: "decimal",
                textAlign: "left",
                fontSize: "12px",
                padding: "0px 10px",
              }}
            >
              <li
                style={{
                  listStyleType: "decimal",
                  paddingLeft: "10px",
                  textAlign: "justify",
                }}
              >
                Calibration Certificate includes all the information as agreed
                with Customer and as necessary for the interpretation of the
                results and all information required by the method used. All
                issued reports are retained as Technical records by our
                Laboratory as per ISO/IEC 17025 requirements.
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  paddingLeft: "10px",
                  textAlign: "justify",
                }}
              >
                Metrological Traceability to National / International Standards
                as per ISO/IEC 17025 requirements are maintained by our
                Laboratory and our Calibration reports do not contain any
                recommendation on Calibration interval, except where this has
                been agreed with the Customer.
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  paddingLeft: "10px",
                  textAlign: "justify",
                }}
              >
                Calibration Certificate are issued in accordance with terms and
                conditions of accreditation laid down by our Accreditation Body
                which has assessed our measurement capability and its
                traceability to recognized National / International Standards.{" "}
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  paddingLeft: "10px",
                  textAlign: "justify",
                }}
              >
                Calibration Certificate pertains only to the particular item(s)
                submitted & reported for Calibration and shall not be reproduced
                except in full, without the written approval from our
                Laboratory.
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  paddingLeft: "10px",
                  textAlign: "justify",
                }}
              >
                Results reported in the Calibration Certificate are valid at the
                time of and understand conditions of measurements of the Units
                under Calibration. Errors if any, shall be brought to notice of
                our Laboratory within 30 days from the date of Issue. Changes to
                Certificates will not be entertained thereafter.
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  paddingLeft: "10px",
                  textAlign: "justify",
                }}
              >
                Calibration Certificate is issued based on Customer requirements
                for Scientific and Industrial Purposes and not meant for other
                purpose & shall not be produced in the court of law.
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  paddingLeft: "10px",
                  textAlign: "justify",
                }}
              >
                Jayasree Reva Phoenix Metrology Private Limited Labortory Division is
                not liable for any change in the reported data and performance
                specification on account of malfunctioning of Standards /
                Instruments | Equipment covered by this certificate due to
                damage caused to it after the issue of this certificate.
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  paddingLeft: "10px",
                  textAlign: "justify",
                }}
              >
                NABL Guidelines 133 is adopted for the use of NABL symbol.
              </li>
            </ol>
          </div>
          <div
            style={{
              marginTop: "5px",
              width: "100%",
              margin: "0 auto",
              padding: "3px 10px",
              textAlign: "left",
              fontSize: "12px",
            }}
          >
            <p
              style={{
                color: "black",
                fontWeight: "600",
                fontSize: "14px",
                fontFamily: "Poppins, sans-serif",
                marginTop: "8px",
              }}
            >
              For any further information, assistance or feedback, please feel
              free to Contact:
            </p>
            <p
              style={{
                color: "black",
                fontFamily: "Poppins, sans-serif",
                fontSize: "12px",
                lineHeight: "16px",
              }}
            >
              Director | Managing Director
            </p>
            <p
              style={{
                color: "black",
                fontFamily: "Poppins, sans-serif",
                fontSize: "12px",
                lineHeight: "16px",
              }}
            >
             Jayasree Reva Phoenix Metrology Private Limited
            </p>
            <p
              style={{
                color: "black",
                fontFamily: "Poppins, sans-serif",
                fontSize: "12px",
                lineHeight: "16px",
              }}
            >
              Reva Phoenix Complex, No. 14, 4th Street,
            </p>{" "}
            <p
              style={{
                color: "black",
                fontFamily: "Poppins, sans-serif",
                fontSize: "12px",
                lineHeight: "16px",
              }}
            >
              Rajarajeswari Nagar, Madipakkam,
            </p>{" "}
            <p
              style={{
                color: "black",
                fontFamily: "Poppins, sans-serif",
                fontSize: "12px",
                lineHeight: "16px",
              }}
            >
              Chennai 600 091, Tamil Nadu, India
            </p>
            <p
              style={{
                color: "black",
                fontFamily: "Poppins, sans-serif",
                fontSize: "12px",
                lineHeight: "16px",
              }}
            >
              Mobile: +91 98406 72352 | +91 99405 64783 | +91 99400 47321
            </p>
            Email: enquiry@revaphoenix.com
            <p
              style={{
                color: "black",
                fontFamily: "Poppins, sans-serif",
                fontSize: "12px",
                lineHeight: "16px",
              }}
            >
              {" "}
              Website: www.revacalibration.com
            </p>
          </div>
          <div
            style={{
              width: "100%",
              margin: "0px auto",
              padding: "0px 10px",
              textAlign: "left",
            }}
          >
            <p
              style={{
                marginTop: "8px",
                color: "black",
                fontFamily: "Poppins, sans-serif",
                backgroundColor: "#141571",
                padding: "2px 6px",
                width: "fit-content",
                color: "white",
                borderRadius: "20px",
                fontWeight: "700",
                fontSize: "12px",
              }}
            >
              CALIBRATION SERVICES
            </p>
            <p
              style={{
                color: "black",
                fontFamily: "Poppins, sans-serif",
                marginTop: "2px",
                fontSize: "12px",
                lineHeight: "16px",
              }}
            >
              Mechanical | Dimension - All Basic & Precision Equipment CMM
              Calibration | Pressure - Upto 4000 Bar | Torque - upto 3000 Nm |
              Mass E11 Volume - Micro Pipettel Electro-Technical MFC &
              Oscilloscope | Thermal - Furnace Mapping & Pyrometer upto 1750°C |
              Force | Optics | Light Source | Lux Meter (1 to 2,00,000 Lux) |
              Acceleration | Speed | Tachometer | Rubber Hardness Tester | Fluid
              Flow | Anemometer | Acoustics | Mechanical Testing | Mechanical
              Calibration | Rockwell | Brinell | Vickers | Micro-Vickers | UTM
              Calibration | UTM CTM TTM | Impact Testing Machine Calibration |
              Force Gauge | Push Pull Gauge | Leeb's Hardness | Portable
              Hardness Tester | Dynamic Hardness Tester | E1 Calibration |
              Density & Viscosity | Air Flow Meters | pH Meter | Conductivity
              Meter | Lab Master Equipment Calibration
            </p>
            <p
              style={{
                color: "black",
                fontFamily: "Poppins, sans-serif",
                marginTop: "12px",
                backgroundColor: "#141571",
                padding: "2px 6px",
                width: "fit-content",
                color: "white",
                borderRadius: "20px",
                fontWeight: "700",
                fontSize: "12px",
              }}
            >
              SCOPE OF ACCREDITATION
            </p>
            <p
              style={{
                color: "black",
                fontFamily: "Poppins, sans-serif",
                marginTop: "2px",
                fontSize: "12px",
                lineHeight: "16px",
              }}
            >
              Please contact our Laboratory to get a copy of our ISO/IEC 17025
              Certificate and Accredited Scope of Calibration. You may also
              download the same from NABL website.
            </p>
          </div>
        </div>
      );
    }
    function customerDetailsTable() {
      return (
        <>
          <Table className={classes.table} style={{ marginBottom: "5px" }}>
            <TableRow className={classes.row}>
              <TableCell
                className={classes.addressCell}
                style={{ width: "25%" }}
              >
                <div>
                  <b>Date of Performance of Calibration</b>
                </div>
              </TableCell>
              <TableCell
                style={{
                  width: "25%",
                  borderLeft: "1px solid black",
                  padding: "5px",
                }}
              >
                <div styel={{ padding: "5px" }}>
                  {formatDateByClient(calibrationDate) || "\u00A0"}
                </div>
              </TableCell>
              <TableCell
                style={{
                  width: "25%",
                  borderLeft: "1px solid black",
                  padding: 0,
                }}
              >
                <div
                  style={{ borderBottom: "1px solid black", padding: "5px" }}
                >
                  <b>Date of Issue</b>
                </div>
                <div style={{ padding: "5px" }}>
                  <b>Recommended Due Date</b>
                </div>
              </TableCell>
              <TableCell
                style={{
                  width: "25%",
                  borderLeft: "1px solid black",
                  padding: 0,
                }}
              >
                <div
                  style={{ borderBottom: "1px solid black", padding: "5px" }}
                >
                  {dateOfIssue
                  ? formatDateByClient(dateOfIssue)
                  : formatDateByClient(approvedDate)}
                </div>
                <div style={{ padding: "5px" }}>
                {calibrationFrequency == "-" ? "-" : formatDateByClient(nextDueDate) || "\u00A0"}
                </div>
              </TableCell>
            </TableRow>
          </Table>
          <Table className={classes.table} style={{ marginBottom: "5px" }}>
            <TableRow className={classes.row}>
              <TableCell
                className={classes.addressCell}
                style={{ width: "25%" }}
              >
                <div>
                  <b>Name & address of Customer</b>
                </div>
              </TableCell>
              <TableCell
                style={{
                  width: "25%",
                  borderLeft: "1px solid black",
                  padding: "5px",
                }}
              >
                <div>
                  <b>{clientName}</b>
                </div>
                <div>{newAddressArray?.[0]?.address}</div>
              </TableCell>
              <TableCell
                style={{
                  width: "25%",
                  borderLeft: "1px solid black",
                  padding: 0,
                }}
              >
                <div
                  style={{ borderBottom: "1px solid black", padding: "5px" }}
                >
                  <b>Date Receipt</b>
                </div>
                <div
                  style={{ borderBottom: "1px solid black", padding: "5px" }}
                >
                  <b>CSRF Number</b>
                </div>
                <div style={{ padding: "5px" }}>
                  <b>Location-Calibrated at</b>
                </div>
              </TableCell>
              <TableCell
                style={{
                  width: "25%",
                  borderLeft: "1px solid black",
                  padding: 0,
                }}
              >
                <div
                  style={{ borderBottom: "1px solid black", padding: "5px" }}
                >
                  {formatDateByClient(receiptDate) || "\u00A0"}
                </div>
                <div
                  style={{ borderBottom: "1px solid black", padding: "5px" }}
                >
                  {certificateServiceReqNumber || "\u00A0"}
                </div>
                <div style={{ padding: "5px" }}>{location || "\u00A0"}</div>
              </TableCell>
            </TableRow>
          </Table>
        </>
      );
    }
    function unitCalibrationDetails() {
      return (
        <>
          <table
            className="standardUnitTable single-border-table"
            align="center"
            style={{ marginBottom: "5px" }}
          >
            <tr>
              <td>
                <b>Description</b>
              </td>
              <td>
                {requestedName.length > 0 ? requestedName : instrumentName}
              </td>
              <td>
                <b>Model</b>
              </td>
              <td>{models}</td>
            </tr>
            <tr>
              <td>
                <b>Range</b>
              </td>
              <td>{ranges}</td>
              <td>
                <b>Serial No</b>
              </td>
              <td>{serialNo}</td>
            </tr>
            <tr>
              <td>
                <b>Least Count</b>
              </td>
              <td>
                {lc
                  ?.replaceAll("||", ", ")
                  ?.replaceAll("|", " to ")
                  ?.replaceAll("#", " ")}
              </td>
              <td>
                <b>Identification No</b>
              </td>
              <td>{DUCID}</td>
            </tr>
            <tr>
              <td>
                <b>Make</b>
              </td>
              <td>{make}</td>
              <td>
                <b>Accuracy</b>
              </td>
              <td>
                {accuracyFormat(accuracy)}
              </td>
            </tr>
            {operatingrange || locationOfInstrument ? (
            <tr>
              {operatingrange ? (
                <><td>
                    <b>Operating Range</b>
                  </td>
                  <td>{operatingrange}</td>
                </>
              ) : (
                <>
                  <td></td>
                  <td></td>
                </>
              )}
              {locationOfInstrument ? (
                <>
                  <td>
                    <b>Location</b>
                  </td>
                  <td>{locationOfInstrument ? locationOfInstrument : ""}</td>
                </>
              ) : (
                <>
                  <td></td>
                  <td></td>
                </>
              )}
            </tr>
            ) : null}
            {material || assumeDensity ? (
              <tr>
                {material ? (
                  <>
                    <td>
                      <b>Material</b>
                    </td>
                    <td>{material}</td>
                  </>
                ) : (
                  <>
                    <td></td>
                    <td></td>
                  </>
                )}
                {assumeDensity ? (
                  <>
                    <td>
                      <b>Assumed Density</b>
                    </td>
                    <td>{assumeDensity}</td>
                  </>
                ) : (
                  <>
                    <td></td>
                    <td></td>
                  </>
                )}
              </tr>
            ) : null}
            {coeffthermal || partNo ? (
              <tr>
                {coeffthermal ? (
                  <>
                    <td>
                      <b>Coefficient of thermal expansion</b>
                    </td>
                    <td>{coeffthermal}</td>
                  </>
                ) : (
                  <>
                    <td></td>
                    <td></td>
                  </>
                )}
                {partNo ? (
                  <>
                    <td>
                      <b>Part Number</b>
                    </td>
                    <td>{partNo}</td>
                  </>
                ) : (
                  <>
                    <td></td>
                    <td></td>
                  </>
                )}
              </tr>
            ) : null}
          </table>
        </>
      );
    }
    function masterDataTable() {
      //Please don't put thead or tbody
      return (
        <div align="left">
          <table
            className="standardUnitTable single-border-table"
            align="center"
          >
            <tr>
              <td style={{textAlign:"center",textWrap:"nowrap"}}>
                <b>Sl. No.</b>
              </td>
              <td style={{textAlign:"center"}}>
                <b>
                  Description Master Equipment / Standard used for calibration
                </b>
              </td>
              <td style={{textAlign:"center"}}>
                <b>Traceable to National standards through</b>
              </td>
            </tr>
            {standardMasterArray.map((row, index) => (
              <tr key={row.name}>
                <td style={{textAlign:"center"}}>{index + 1}</td>
                <td>{row.standardName}</td>
                <td>
                  {row.certificateNumber}, Valid till :{" "}
                  {moment(row.validUpto).format("DD.MM.YYYY")}
                </td>
              </tr>
            ))}
          </table>
        </div>
      );
    }
    function opinionAndAnalysis() {
      return (
        <div>
          <div style={{ textAlign: "left" }}>
            <b>Opinion And Analysis</b>
          </div>
          <table
            className="standardUnitTable single-border-table"
            align="center"
          >
            <tr>
              <td>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <div>
                    <span>Accepted - Validate for use</span>
                  </div>
                  {checkboxOptions.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={
                          analysisSelectedOption?.accepted === (item === "✔")
                        }
                        value={item}
                      />
                      <label>{item}</label>
                    </div>
                  ))}
                </div>
              </td>
              <td>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <div>
                    <span>Calibrated</span>
                  </div>
                  {checkboxOptions.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={
                          analysisSelectedOption?.calibrated === (item === "✔")
                        }
                        value={item}
                      />
                      <label>{item}</label>
                    </div>
                  ))}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <div>
                    <span>Limited Use (Refer - Result)</span>
                  </div>
                  {checkboxOptions.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={
                          analysisSelectedOption?.limitedUse === (item === "✔")
                        }
                        value={item}
                      />
                      <label>{item}</label>
                    </div>
                  ))}
                </div>
              </td>
              <td>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <div>
                    <span>Rejected/Out of Use</span>
                  </div>
                  {checkboxOptions.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={
                          analysisSelectedOption?.rejected === (item === "✔")
                        }
                        value={item}
                      />
                      <label>{item}</label>
                    </div>
                  ))}
                </div>
              </td>
            </tr>
          </table>
        </div>
      );
    }
  };
  let nonAdminRoleType = userType != 1 && userType != 4;
    
  const showButton = (button) => {
    if (rejectedBy?.[0]) return false;
    const userTypeBollean = userType == 1 || userType == 4;
    if (!userTypeBollean && !roles_ids?.includes(button)) return false;
    let ret =
      calibratedby &&
      calibratedby.length > 0 &&
      calibratedby[0] !== user[1] &&
      !props.isPrint &&
      status !== -1;

    switch (button) {
      case 3:
        ret =
          ret &&
          approvedby?.[0] &&
          !reviewedBy[0] &&
          ((userTypeBollean &&
            (rolesSettins[3]?.name &&
              `${rolesSettins[3]?.name}`.toLocaleLowerCase() !== "false"
              )) ||
            props.rolesInfo?.currentRole?.id == 3);
        break;
      case 4:
        ret =
          ret &&
          approvedby?.[0] &&
          !signedBy[0] &&
          ((userTypeBollean &&
            (rolesSettins[4]?.name &&
              `${rolesSettins[4]?.name}`.toLocaleLowerCase() !== "false"
              )) ||
            props.rolesInfo?.currentRole?.id == 4);
        break;
      case 5:
        ret =
          ret &&
          !witnessedBy[0] &&
          ((userTypeBollean &&
            (rolesSettins[5]?.name &&
              `${rolesSettins[5]?.name}`.toLocaleLowerCase() !== "false"
              )) ||
            props.rolesInfo?.currentRole?.id == 5);
        break;
      case 1:
        ret =
          ret &&
          !approvedby?.[0] &&
        !reviewedBy[0] &&
        !signedBy[0] &&
        !witnessedBy[0] &&
          !rejectedBy[0] &&
          ((userTypeBollean &&
            (rolesSettins[1]?.name &&
              `${rolesSettins[1]?.name}`.toLocaleLowerCase() !== "false"
              )) ||
            props.rolesInfo?.currentRole?.id != 1);
        break;
      case 2:
        ret =
          ret &&
          !approvedby?.[0] &&
          ((userTypeBollean &&
            (rolesSettins[2]?.name &&
              `${rolesSettins[2]?.name}`.toLocaleLowerCase() !== "false"
              )) ||
            props.rolesInfo?.currentRole?.id == 2);
        break;
      default:
        ret = false;
        break;
    }

    return ret;
  };

  const renderButtonPair = (button, label, color, status, nonAdminRoleType) => (
    <>
      <Button
        id={`certificate-certificateview_${label.toLowerCase()}btn`}
        variant="contained"
        color={color}
        size="small"
        sx={{ ml: 3 }}
        onClick={() => {
          if (label == "Approve" && settingList["Compliance Status"]?.toLowerCase() == "true" && complianceStatus != 1) {
            if (window.confirm("Compliance Status is failed. Do you want approve? ")) {
              ChangeCertificateStatus(status, [id]);
            }
          } else {
            ChangeCertificateStatus(status, [id]);
          }
        }}        
      >
        {label}
      </Button>
      {nonAdminRoleType && ( <Button
        id="certificate-certificateview_Rejectbtn"
        variant="contained"
        color="error"
        size="small"
        sx={{ ml: 3 }}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Reject
      </Button>
      )}
    </>
  );

  const renderAdminRejectButton = () => (
    (showButton(3) || showButton(4) || showButton(5) || showButton(2)) && (
      <Button
        id="certificate-certificateview_Rejectbtn"
        variant="contained"
        color="error"
        size="small"
        sx={{ ml: 3 }}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Reject
      </Button>
    )
  );

  const ShowRejectModal = () => {
    const [rejectReason, setRejectReason] = useState("");
    return (
      <Modal
        open={modalOpen}
        onClose={(e, r) => {
            handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={modalStyle}>
          <TextField
            id="outlined-basic"
            label="Reason For Rejection"
            value={rejectReason}
            multiline
            maxRows={5}
            rows={5}
            size="small"
            fullWidth
            variant="outlined"
            style={{ marginBottom: "5px" }}
            onChange={(e) => setRejectReason(e.target.value)}
          />
          <Tooltip title="Send Rejection Reason" placement="top-start">
            <Button
              variant="contained"
              color="error"
              size="small"
              sx={{ m: 2 }}
              onClick={() => {
                ChangeCertificateStatus("reject",[id],calibratedby,instrumentName,DUCID, rejectReason,props.rolesInfo?.currentRole?.name,approvedby);
              }}
              disabled={rejectReason === ""}
            >
              Reject
            </Button>
          </Tooltip>
        </Box>
      </Modal>
    );
  };

  return (
    <>
      {!props.isPrint && !props.certificateIds && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Tooltip title="User Guide" placement="top-start">
            <Button
              onClick={() => {
                viewCertificateGuides.drive();
              }}
            >
              <HelpIcon />
            </Button>
          </Tooltip>
        </div>
      )}

      {paramCertificateId && (
        <Toolbar style={{ padding: "0px", width: "95%", margin: "auto" }}>
          {userType == 3 ? (
            // Show content for userType 3
            <Grid container spacing={2}>
              {/* Elements for userType 3 */}

              <Grid item xs={12} textAlign={"right"}>
                <Button
                  id="certificate-certificateviewDownalod"
                  variant="contained"
                  size="small"
                  disabled={!(editAccess?.includes(3) || editAccess?.includes(0))}
                  // sx={{ ml: 3 }}
                  onClick={() => {
                    if(paramMultipleCertificateIdsArray.length > 1) {                      
                      setpdfGenerated(true);
                    }else{
                    generatePDF();
                    downloadAuditlog();
                    }
                  }}
                >
                  {approvedby ? "Download PDF" : "Download Draft"}
                </Button>
              </Grid>
            </Grid>
          ) : // For other userTypes
          ((userType === 1 || userType === 4) &&
              approvedby &&
              !props.certificateIds) ||
            (!props.isPrint && approvedby && !props.certificateIds) ? (
            <Grid container spacing={2} align="left">
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  disabled={draft}
                  size="small"
                  id="certificate-certificateviewletterhead"
                  options={[
                    { id: 1, label: "With Letter Head" },
                    { id: 2, label: "Without Letter Head" },
                  ]}
                  renderInput={(params) => (
                    <TextField {...params} label="letterHead" />
                  )}
                  onInputChange={(event, newInputValue) => {
                    if (
                      newInputValue === "With Letter Head" ||
                      newInputValue === ""
                    ) {
                      setLetterHead(true);
                      setDraft(false);
                    } else if (newInputValue === "Without Letter Head") {
                      setLetterHead(false);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  size="small"
                  id="certificate-certificateviewelectronicsign"
                  options={[
                    { id: 1, label: "With Electronically Signed" },
                    { id: 3, label: "Signature Image" },
                    { id: 2, label: "Without  Electronically Signed" },
                  ]}
                  renderInput={(params) => (
                    <TextField {...params} label="Electronically Signed" />
                  )}
                  onInputChange={(event, newInputValue) => {
                    if (
                      newInputValue === "With Electronically Signed" ||
                      newInputValue === ""
                    ) {
                      setElectronicSign(true);
                    } else if (
                      newInputValue === "Without  Electronically Signed"
                    ) {
                      setElectronicSign(false);
                      setSignImg(false);
                      }
                      else if (
                        newInputValue === "Signature Image"
                      ) {
                      setElectronicSign(false);
                      setSignImg(true);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Autocomplete
                  disabled={letterHead}
                  size="small"
                  options={[
                    { id: 1, label: "With Draft" },
                    { id: 2, label: "Without Draft" },
                  ]}
                  renderInput={(params) => (
                    <TextField {...params} label="Draft " />
                  )}
                  onInputChange={(event, newInputValue) => {
                    if (
                      newInputValue === "With Draft" ||
                      newInputValue === ""
                    ) {
                      setDraft(true);
                      setLetterHead(false);
                    } else if (newInputValue === "Without Draft") {
                      setDraft(false);
                      // setLetterHead(true)
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={3} md={2} lg={2}>
                <Button
                  id="certificate-certificateviewDownalod"
                  variant="contained"
                  size="small"
                  disabled={!(editAccess?.includes(3) || editAccess?.includes(0))}
                  onClick={() => {
                    if(paramMultipleCertificateIdsArray.length > 1) {                      
                      setpdfGenerated(true);
                    }else{
                    generatePDF();
                    downloadAuditlog();
                    }
                  }}
                >
                  Download PDF
                </Button>
              </Grid>
              <Grid item xs={6} sm={3} md={2} lg={2}>
                <Button
                  id="certificate-certificateviewDownalod"
                  variant="contained"
                  size="small"
                  disabled={!(editAccess?.includes(3) || editAccess?.includes(0))}
                  onClick={copyToClipboard}
                  startIcon={<ContentCopyIcon />}
                >
                  Copy URL
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </Toolbar>
      )}
      {props.isPrint === true && (
        <Grid item xs={2}>
          <Button
            id="certificate-certificateviewDownalod"
            variant="contained"
            size="small"
            // disabled={!(editAccess?.includes(3) || editAccess?.includes(0))}
            onClick={() => {
              if (storedDeviceInfo === "mobile" || storedDeviceInfo === "tablet") {
                alert("To download PDF, please use the desktop site.");
              } else {
                if(paramMultipleCertificateIdsArray.length > 1) {                  
                  setpdfGenerated(true);
                }else{
                generatePDF();
                }
              }
            }}
          >
            Download PDF
          </Button>
        </Grid>
      )}

      <div style={{width: props.isPrint === true ? "85%" : "100%",margin:"auto"}}>
      {printView()}
      </div>

      <Toolbar style={{ padding: "0px", width: "100%" }}>
      {showButton(3) && renderButtonPair(3, 'Review', 'success', 'review',nonAdminRoleType)}
      {showButton(4) && renderButtonPair(4, 'Sign', 'error', 'sign',nonAdminRoleType)}
      {showButton(5) && renderButtonPair(5, 'Witnessed', 'info', 'witness',nonAdminRoleType)}
      {showButton(2) && renderButtonPair(2, 'Approve', 'success', 'approve',nonAdminRoleType)}
      {(userType == 1 || userType == 4) ? renderAdminRejectButton() : showButton(1)}
        {/* {showButton(3) && (
          <>
          <Button
            id="certificate-certificateview_reviewbtn"
            variant="contained"
            color="success"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              ChangeCertificateStatus("review",[id]);
            }}
          >
            Review
          </Button>
          <Button
            id="certificate-certificateview_Rejectbtn"
            variant="contained"
            color="error"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Reject
          </Button>
        </>
        )}
        {showButton(4) && (
          <Button
            id="certificate-certificateview_signbtn"
            variant="contained"
            color="error"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              ChangeCertificateStatus("sign",[id]);
            }}
          >
            Sign
          </Button>
        )}
        {showButton(5) && (
          <Button
            id="certificate-certificateview_Witnessedbtn"
            variant="contained"
            color="info"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              ChangeCertificateStatus("witness",[id]);
            }}
          >
            Witnessed
          </Button>
        )}
        {showButton(1) && (
          <Button
            id="certificate-certificateview_Rejectbtn"
            variant="contained"
            color="error"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Reject
          </Button>
        )}
        {showButton(2) && (
          <Button
            id="certificate-certificateview_Approvebtn"
            variant="contained"
            color="success"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              ChangeCertificateStatus("approve",[id]);
            }}
          >
            Approve
          </Button>
        )} */}
      
      </Toolbar>
      {ShowRejectModal()}
      
      {downloadPdfloading && (
        <DownloadPdfLoaderModal
          loading={downloadPdfloading}
          name={"Certificate"}
          abort={true} 
        />
      )}
      {errormodalIsOpen && (
        <ErrorModal
          errormodalIsOpen={errormodalIsOpen}
          closeErrorModal={closeErrorModal}
          errorMessage={errorMessage}
        />
      )}
    </>
  );
}
